import React from "react";
import styled from "styled-components";

import heroImg from "assets/home/downloads-unlocked.webp";
import bottomWave from "assets/home/hero-wave.svg";
import { PrimaryButton } from "components/shared/Buttons/Buttons2";

import TrustMetrics from "./TrustMetrics";

export default function HeroSection() {
  return (
    <>
      <Wrapper>
        <Content>
          <Left>
            <Title>
              <Line1>The easiest way for</Line1>
              <REP>Real Estate Photographers</REP>
              <Line2>
                to <Highlight>stop chasing payments</Highlight>.
              </Line2>
            </Title>

            <SubTitle>
              <SubLine1>Content delivery & pay-to-download invoicing </SubLine1>
              that gets you paid on time, every time.
            </SubTitle>
            <Buttons>
              <ButtonWrapper>
                <a href="https://app.photoinvoice.com/signup">
                  <PrimaryButtonCustom>Get Started Free</PrimaryButtonCustom>
                </a>
              </ButtonWrapper>
            </Buttons>
            <TrustMetrics />
          </Left>

          <Right>
            <HeroImg
              src={heroImg}
              alt="Photos, files, and links unlock after payment"
            />
          </Right>
        </Content>
      </Wrapper>

      <BottomWave src={bottomWave} />
    </>
  );
}

const Wrapper = styled.div`
  padding: 80px 40px 40px;
  background-color: var(--primary);
  position: relative;
  z-index: 100;

  @media (max-width: 1800px) {
    padding: 40px 40px 30px;
  }

  @media (max-width: 1020px) {
    padding: 30px 30px 30px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  color: white;
  text-align: left;
  font-size: 48px;

  @media (max-width: 1380px) {
    font-size: 42px;
  }

  @media (max-width: 1200px) {
    font-size: 36px;
  }

  @media (max-width: 1020px) {
    font-size: 32px;
  }

  @media (max-width: 900px) {
    font-size: 36px;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 28px;
  }
  @media (max-width: 450px) {
    font-size: 26px;
  }
`;

const Line1 = styled.span`
  display: block;
  white-space: nowrap;

  @media (max-width: 430px) {
    display: inline;
    white-space: normal;
  }
`;

const REP = styled.span`
  display: block;
  white-space: nowrap;

  @media (max-width: 430px) {
    display: inline;
    white-space: normal;
    &::before {
      content: " ";
    }
  }
`;

const Line2 = styled.span`
  display: block;
  white-space: nowrap;

  @media (max-width: 430px) {
    display: inline;
    white-space: normal;
    &::before {
      content: " ";
    }
  }
`;

const Highlight = styled.span`
  color: var(--accentGreen);
`;

const SubTitle = styled.div`
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
  font-size: 21px;

  @media (max-width: 1020px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
`;

const SubLine1 = styled.span`
  display: block;

  @media (max-width: 430px) {
    display: inline;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  height: 68px;
  padding: 0 50px;
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 20px;
    padding: 0 30px;
    height: 50px;
  }
`;

const HeroImg = styled.img`
  display: block;
  width: 100%;
  max-width: 690px;
  margin: 30px auto;
`;

const BottomWave = styled.img`
  width: 100%;
  margin-top: -2px;

  @media (max-width: 900px) {
    display: none;
  }
`;
