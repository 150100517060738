export const MAX_CONTENT_WIDTH = "1100px";

export const colors = {
  // primary: "#033A5D",
  primary: "#004C7C",
  ltPrimaryBackground: "rgba(83, 164, 215, 0.1)",

  smokey: "#0A0908",
  raisin: "#252323",
  rose: "#EB5160",
  roseHover: "#d93c4b",
  offWhite: "#F7F9F9",

  accentGreen: "#23c5a1",

  // OLD
  accent: "#01BCF0",
  accentHover: "#00a3d0",
  accentDark: "#2d8dd6",
  ltGray: "#F6F7F8",
  dkBlue: "#012A45",
  ltBlueBackground: "rgba(45, 141, 214, 0.1)",
  dkGray: "#0f0f0f",
};
