import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

import Container from "components/shared/Container";
import invoiceListPaid from "assets/home/invoiceListPaid.webp";
import invoiceListUnpaid from "assets/home/invoiceListUnpaid.webp";
import invoiceListCombined from "assets/home/invoiceListCombined.webp";
import arrow from "assets/home/arrow.png";

export default function FundamentallyDifferent() {
  const [arrowRef, isInView] = useInView();

  return (
    <SContainer>
      <Title>A fundamentally different invoicing experience</Title>
      <Description>
        Your clients need the finals! So when your invoice only releases them
        after payment, they pay fast, every time.{" "}
        <strong>It's invoicing & delivery on autopilot.</strong>
      </Description>
      <Sections>
        <Section>
          <Header>
            <SectionTitleOther>Other invoicing tools</SectionTitleOther>
          </Header>
          <Content>
            <List>
              <li>
                <Emoji>⏳</Emoji> Payments are slow and often late
              </li>
              <li>
                <Emoji>💸</Emoji>You're waiting on thousands of dollars
              </li>
              <li>
                <Emoji>😭</Emoji>That one client just won't pay
              </li>
            </List>
          </Content>
          <BeforeImg src={invoiceListUnpaid} />
        </Section>
        <SectionPI>
          <Header>
            <SectionTitlePI>PhotoInvoice</SectionTitlePI>
          </Header>
          <Content>
            <List>
              <li>
                <Emoji>⚡</Emoji>Clients pay on time, every time
              </li>
              <li>
                <Emoji>✅</Emoji>No late payments to track down
              </li>
              <li>
                <Emoji>🏖️</Emoji>No messy invoice list or reminders to send
              </li>
            </List>
          </Content>
          <ImgWrapper>
            <AfterImg src={invoiceListPaid} />
            <CombinedImg src={invoiceListCombined} />
          </ImgWrapper>
        </SectionPI>
        <Arrow
          ref={arrowRef}
          src={arrow}
          intial={{ x: -200 }}
          animate={{ x: isInView ? 0 : -200 }}
        />
      </Sections>
    </SContainer>
  );
}

const otherColor = "#e21102";

const SContainer = styled(Container)`
  padding: 60px 0;

  @media (max-width: 800px) {
    padding: 40px 0;
  }

  @media (max-width: 650px) {
    padding: 30px 0 0;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 60px;
  font-size: 36px;

  @media (max-width: 950px) {
    font-size: 32px;
    margin-bottom: 40px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 460px) {
    font-size: 21px;
  }
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 60px;
  max-width: 680px;
  line-height: 1.4;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 650px) {
    font-size: 17px;
  }
`;

const Sections = styled.div`
  position: relative;
  display: flex;
  margin-top: 40px;

  @media (max-width: 650px) {
    flex-direction: column;
    margin: 0 0 40px;
  }
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;

  @media (max-width: 900px) {
    padding: 0 20px;
  }

  @media (max-width: 700px) {
    padding: 20px;
    margin: 0 10px;
  }
`;

const SectionPI = styled(Section)`
  @media (max-width: 650px) {
    margin-top: -40px;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 10px 40px;
  border-radius: 30px;
  color: white;
  font-size: 21px;
  font-weight: 600;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 830px) {
    font-size: 18px;
  }
`;

const SectionTitleOther = styled(SectionTitle)`
  background-color: ${otherColor};
`;
const SectionTitlePI = styled(SectionTitle)`
  background: var(--accentGreen);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.3;

  @media (max-width: 850px) {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const List = styled.ul`
  margin-top: 1rem;
  list-style: none;
  text-align: left;

  li {
    margin: 10px 0 10px 30px;
    display: flex;

    @media (max-width: 1000px) {
      margin-left: 0;
    }

    @media (max-width: 680px) {
      margin-left: 30px;
    }

    @media (max-width: 480px) {
      margin-left: 0;
    }
  }
`;

const Emoji = styled.span`
  display: block;
  margin-right: 10px;
`;

const Img = styled.img`
  max-width: 100%;
  width: 360px;
  margin: 20px auto;

  @media (max-width: 650px) {
    display: none;
  }
`;

const BeforeImg = styled(Img)``;

const AfterImg = styled(Img)``;

const CombinedImg = styled(Img)`
  display: none;

  @media (max-width: 650px) {
    display: block;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const Arrow = styled(motion.img)`
  position: absolute;
  bottom: 90px;
  right: calc(50% - 60px);
  width: 120px;

  @media (max-width: 1000px) {
    width: 80px;
    right: calc(50% - 40px);
    bottom: 120px;
  }

  @media (max-width: 760px) {
    width: 60px;
    right: calc(50% - 30px);
    bottom: 110px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;
