import React from "react";
import styled from "styled-components";
import { colors } from "Constants";
export default function TrustMetrics() {
  return (
    <Wrapper>
      <Metric>
        <Value>
          61k<Plus>+</Plus>
        </Value>
        <Label>invoices</Label>
      </Metric>
      <Metric>
        <Value>
          $12M<Plus>+</Plus>
        </Value>
        <Label>processed</Label>
      </Metric>
      <Metric>
        <Value>
          18k<Plus>+</Plus>
        </Value>
        <Label>agents & clients</Label>
      </Metric>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 50px;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  gap: 30px;

  background: rgba(216, 216, 216, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  color: white;

  @media (max-width: 1020px) {
    padding: 12px;
    max-width: 400px;
  }

  @media (max-width: 900px) {
    margin: 40px auto 0;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 34px;
  font-weight: 600;

  @media (max-width: 1200px) {
    font-size: 28px;
  }

  @media (max-width: 1020px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    font-size: 21px;
  }
`;

const Value = styled.div`
  font-family: "Merriweather";
`;

const Plus = styled.span`
  color: ${colors.accent};
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";

  @media (max-width: 1020px) {
    font-size: 14px;
  }
`;
